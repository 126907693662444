import themes from "daisyui/src/theming/themes.js";
import { ConfigProps } from "./types/config";

export const isDevelopment = (process.env.NODE_ENV === "development");
const inWaitingListMode = false;

const config = {
  tenant: "supadoc",
  inWaitlistMode: inWaitingListMode,
  // REQUIRED
  appName: "Supa Doc",
  // REQUIRED: a short description of your app for SEO tags (can be overwritten)
  appDescription:
    "Supa Doc: GPT4 Powered AI Document Generating, can generate Marketing Plan, Software System, Function Design, Test Case, Business Plan, etc.",
  // REQUIRED (no https://, not trialing slash at the end, just the naked domain)
  domainName: "www.supadoc.ai",
  crisp: {
    // Crisp website ID. IF YOU DON'T USE CRISP: just remove this => Then add a support email in this config file (mailgun.supportEmail) otherwise customer support won't work.
    id: "cc1dcf78-4b96-4a35-8b3b-a25bcc2b6589",
    // Hide Crisp by default, except on route "/". Crisp is toggled with <ButtonSupport/>. If you want to show Crisp on every routes, just remove this below
    // onlyShowOnRoutes: ["/"],
  },
  stripe: {
    // Create multiple plans in your Stripe dashboard, then add them here. You can add as many plans as you want, just make sure to add the priceId
    plans: [
      {
        "priceId": isDevelopment ? "price_1OGCmLLctKQxiuRhwgZxos8T" : "price_1OGCmLLctKQxiuRhwgZxos8T",
        "name": "Basic Plan",
        "description": "AI-powered document creation. Ideal for occasional personal use.",
        "price": 0,
        "mode": "payment",
        "paymentButtonLabel": "Get Started for Free",
        "belowButtonDescription": "No credit card required",
        "features": [
          { "name": "2 AI Document Creations per Month" },
          { "name": "Basic Customer Support" }
        ]
      },
      {
        "priceId": isDevelopment ? "price_1OFxDLLctKQxiuRha7NSdErb" : "price_1OFxDLLctKQxiuRha7NSdErb",
        "name": "Pay-As-You-Go",
        "description": "Flexible document creation for your varying needs. Pay for what you use.",
        "price": 8,
        "priceAnchor": 10,
        "pricePerUnit": "10 Runs",
        "mode": "payment",
        "paymentButtonLabel": "Purchase Runs",
        "belowButtonDescription": "Use anytime, no expiration",
        "features": [
          { "name": "10 AI Document Creations" },
          { "name": "Enhanced Customer Support" }
        ]
      },
      {
        "priceId": isDevelopment ? "price_1OFxDlLctKQxiuRh3XRuxmac" : "price_1OFxDlLctKQxiuRh3XRuxmac",
        "yearlyPriceId": isDevelopment ? "price_1OG28kLctKQxiuRhfZj6fW9F" : "price_1OG28kLctKQxiuRhfZj6fW9F",
        "isFeatured": true,
        "name": "Subscription Plan",
        "description": "Unleash the full potential of AI document creation for frequent use.",
        "price": 12,
        "priceAnchor": 30,
        "mode": "subscription",
        "paymentButtonLabel": "Subscribe Now",
        "belowButtonDescription": "Best Value",
        "features": [
          { "name": "30 AI Document Creations per Month" },
          { "name": "Priority Customer Support" }
        ],
      }
    ]
    ,
  },
  aws: {
    // If you use AWS S3/Cloudfront, put values in here
    bucket: "bucket-name",
    bucketUrl: `https://bucket-name.s3.amazonaws.com/`,
    cdn: "https://cdn-id.cloudfront.net/",
  },
  mailgun: {
    // subdomain to use when sending emails, if you don't have a subdomain, just remove it. Highly recommended to have one (i.e. mg.yourdomain.com or mail.yourdomain.com)
    subdomain: "m",
    // REQUIRED — Email 'From' field to be used when sending magic login links
    fromNoReply: `Muyan <support@muyan.cloud>`,
    // REQUIRED — Email 'From' field to be used when sending other emails, like abandoned carts, updates etc..
    fromAdmin: `Lawrence at Muyan <xiangqian.liu@muyan.cloud>`,
    // Email shown to customer if need support. Leave empty if not needed => if empty, set up Crisp above, otherwise you won't be able to offer customer support."
    supportEmail: "support@muyan.cloud",
    // When someone replies to supportEmail sent by the app, forward it to the email below (otherwise it's lost). If you set supportEmail to empty, this will be ignored.
    forwardRepliesTo: "support@muyan.cloud",
  },
  colors: {
    // REQUIRED — The DaisyUI theme to use (added to the main layout.js). Leave blank for default (light & dark mode). If you any other theme than light/dark, you need to add it in config.tailwind.js in daisyui.themes.
    theme: "dark",
    // REQUIRED — This color will be reflected on the whole app outside of the document (loading bar, Chrome tabs, etc..). By default it takes the primary color from your DaisyUI theme (make sure to update your the theme name after "data-theme=")
    // OR you can just do this to use a custom color: main: "#f37055". HEX only.
    main: themes[`[data-theme=light]`]["primary"],
  },
  auth: {
    // REQUIRED — the path to log in users. It's use to protect private routes (like /dashboard). It's used in apiClient (/libs/api.js) upon 401 errors from our API
    loginUrl: "/signin",
    // REQUIRED — the path you want to redirect users after successfull login (i.e. /dashboard, /private). This is normally a private page for users to manage their accounts. It's used in apiClient (/libs/api.js) upon 401 errors from our API & in ButtonSignin.js
    callbackUrl: "/dashboard",
  },
  support: {
    slackUrl: "http://slack.supa.is",
    twitterUrl: "https://x.com/xqliu",
  },
  purchaseWithoutLogin: false,
  activePaymentProvider: "lemonsqueeze",
  logSnag: {
    token: "647c34224a285f50188184ee99909605",
    project: "muyan_io",
    ignoreEmails: [
      "smartlitchi@gmail.com",
      "redcamper@163.com",
      "zhaozili1002@gmail.com",
      "create3000@gmail.com",
      "xiangqian.liu@muyan.cloud",
      "create-3000@sohu.com",
      "liaoyuan.shen@outlook.com",
      "love.xqliu@outlook.com",
    ]
  },
} as ConfigProps;

export const shouldDisplayAllHistory = (email: string) => {
  return config.logSnag.ignoreEmails.includes(email);
}

export const shouldTrack = (email: string) => {
  // shouldTrack should return false for localhost and for emails in the ignoreEmails array
  if (process.env.NODE_ENV === "development") {
    return false;
  }
  return !config.logSnag.ignoreEmails.includes(email);
}

export default config;
